.mapFilter {
  &Container {
    z-index: 1500;
    background-color: white;
    width: 300px;
    min-height: 90px;
    border-radius: 0 0 0 10px;
    padding: 5px;
    max-height: 330px;
    overflow-y: auto;
  }

  &Container.hidden {
    display: none;
    z-index: -1000;
  }
}

.mapDraggableTitle {
  cursor: move;

  .action {
    cursor: pointer;

    &:hover {
      color: rgb(171, 171, 221);
    }
  }
}

.title-filter {
  font-size: 0.8em;
}

.bg-light-2 {
  background-color: #f0f0f0;
}
