.custom-chip {
    background-color: white !important;
    border-color: #c62828 !important;
    border: 1px solid #c62828ed !important;
    margin: 3px !important;
    span {
        color: #c62828 !important;
    }
    .MuiChip-deleteIcon {
        color: #c62828 !important;
    }
}